<template>
  <v-dialog
    v-model="dialog"
    max-width="700"
    >
    <v-card>
      <v-card-title>
        Выберите вложенный документ
      </v-card-title>
      <v-divider />
      
      <template v-for="file in files">
        <v-list-item :key="file.id" @click="openFile(file.id)">
          <v-list-item-content>
            <v-list-item-title>{{file.name}}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon x-small>mdi-arrow-collapse-down</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-divider />
      </template>
      
      <v-col class="text-right">
        <v-btn
            outlined
            color="primary"
            @click="dialog = false"
            >
          Отмена
        </v-btn>
      </v-col>
      
    </v-card>
  </v-dialog>
</template>

<script>
    
import BaseAction from "@/components/actions/BaseAction";

export default {
  name: 'OpenFile',
  mixins: [
    BaseAction,
  ],
  data: () => ({
    dialog: false,
    files: [],
  }),
  mounted(){
    if (!this.selected) {
      return;
    }
    this.getCertificateFiles();
  },
  methods:{
    getCertificateFiles() {
      this.prepareFile(this.selected.vccertificateregisterFile);
      this.prepareFile(this.selected.vccertificateregisterFile1);
      this.prepareFile(this.selected.vccertificateregisterFile2)
      
      if (this.files.length > 0) {
        if (this.files.length === 1) {
          this.openFile(this.files[0].id);
        } else {
          this.dialog = true;
        }
      } else {
        this.showMessage('warning', "Документ не добавлен в систему для свидетельства серия " 
                + this.selected.vccertificateregisterCardseries 
                + " номер " + this.selected.vccertificateregisterCardnumber);
      }
    },
    prepareFile(file) {
      if (file) {
        const attrs = file.attrs;
        const fileProps = Object.assign({}, {
          id: file.id,
          name: attrs['Key{name=fileName, type=java.lang.String}'],
          mimeType: attrs['Key{name=mimeType, type=java.lang.String}'],
          size: attrs['Key{name=fileSize, type=java.lang.Long}']
        });
        this.files.push(fileProps);
      }   
    },
    openFile(fileID) {
      location.href = '/rpc/?d=file&uri=fs:id:' + fileID;
      this.dialog = false;
    },
  },
}
</script>
